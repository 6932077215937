<template>
  <my-doctor-template
    :already-patient="alreadyPatient"
    :doctor-full-name="doctorFullName"
    :is-associated-with-clinic-visible="isAssociatedWithClinicVisible"
    :is-associated-with-clinic="isAssociatedWithClinic"
    :want-to-connect-with-clinic-visible="wantToConnectWithClinicVisible"
    :want-to-connect-with-clinic="wantToConnectWithClinic"
    :clinic-locations="clinicLocations"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import MyDoctorTemplate from '@/modules/questionnaire/components/steps/common/my-doctor/my-doctor/MyDoctorTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { types } from '@/modules/questionnaire/store/types';

import { yes, no } from '@/modules/questionnaire/api/helpers';

const trifectaClinicId = 242;

const fieldsToReset = ['isAssociatedWithClinic', 'wantToConnectWithClinic'];

const requiredFields = ['alreadyPatient', 'isAssociatedWithClinic', 'wantToConnectWithClinic'];

const { requiredField } = fieldBuilder;

const FIELDS = [...requiredFields.map(requiredField)];

const UNSURE = 'I am not sure';

const CLINICS = {
  long_island: 'Hewlett Long Island',
  downtown: 'Downtown NYC',
  fifty_seven: '57th St NYC',
  unsure: 'No Location'
};

export default {
  name: 'MyDoctor',
  components: {
    MyDoctorTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    ...mapGetters({
      doctor: types.getters.GET_DOCTOR,
      doctorFullName: types.getters.GET_DOCTOR_FULL_NAME,
      clinicLocations: types.getters.GET_DOCTOR_CLINIC_LOCATIONS,
      patient: types.getters.GET_PATIENT
    }),
    isDoctorHasLocations() {
      return this.clinicLocations.length > 1;
    },
    isDoctorTrifectaClinic() {
      // Used for comment feature for Trifecta Clinic patients.
      return this.doctor.id === trifectaClinicId;
    },
    isAssociatedWithClinicVisible() {
      return yes(this.alreadyPatient) && this.isDoctorHasLocations;
    },
    associatedWithClinicBindings() {
      return {
        values: this.doctorClinicLocations
      };
    },
    wantToConnectWithClinicVisible() {
      return no(this.alreadyPatient) && this.isDoctorHasLocations;
    },
    isFirstQuestionnaire() {
      const { questionnaires } = this.patient;

      return (
        questionnaires &&
        (questionnaires.length === 0 ||
          (questionnaires.length === 1 && questionnaires[0].status === 'ONGOING'))
      );
    }
  },
  watch: {
    alreadyPatient() {
      if (!this.isDoctorHasLocations) {
        this.showNextStep();

        return;
      }

      this.resetStoreFieldsByName(fieldsToReset);
    },
    isAssociatedWithClinic(newValue) {
      if (!newValue) {
        return;
      }

      const clinicDto = this.clinicLocations.find(
        ({ clinicName }) => clinicName === this.isAssociatedWithClinic
      );

      if (this.isDoctorTrifectaClinic && this.isFirstQuestionnaire) {
        const comments = `Existing patient - ${CLINICS[newValue]}`;

        this.updatePatients({ clinicDto, comments });
      } else {
        this.updatePatients({ clinicDto });
      }

      this.showNextStep();
    },
    wantToConnectWithClinic(newValue) {
      if (!newValue) {
        return;
      }

      const clinic = this.clinicLocations.find(
        ({ clinicName }) => clinicName === this.wantToConnectWithClinic
      );
      const clinicDto = newValue === UNSURE ? null : clinic;

      if (this.isDoctorTrifectaClinic && this.isFirstQuestionnaire) {
        const comments = ` New patient - ${CLINICS[newValue]}`;

        this.updatePatients({ clinicDto, comments });
      } else {
        this.updatePatients({ clinicDto });
      }

      this.showNextStep();
    }
  },
  methods: {
    ...mapActions({
      updatePatients: types.actions.UPDATE_PATIENT
    }),
    isFieldVisible(fieldName) {
      if (fieldName === 'isAssociatedWithClinic') {
        return this.isAssociatedWithClinicVisible;
      }

      if (fieldName === 'wantToConnectWithClinic') {
        return this.wantToConnectWithClinicVisible;
      }

      return true;
    }
  }
};
</script>
